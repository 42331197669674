/*
Template Name: Permatic Portal
Author: telerion
Version: 1.1.2
Website: https://telerion.com/
Contact: support@telerion.com
File: Main Js File
*/

!function ($) {
    "use strict";

    var Components = function () { };

    //initializing tooltip
    Components.prototype.initTooltipPlugin = function () {
        $.fn.tooltip && $('[data-toggle="tooltip"]').tooltip()
    },

    //initializing popover
    Components.prototype.initPopoverPlugin = function () {
        $.fn.popover && $('[data-toggle="popover"]').popover()
    },

    //initializing toast
    Components.prototype.initToastPlugin = function() {
        $.fn.toast && $('[data-toggle="toast"]').toast()
    },

    //initializing Slimscroll
    Components.prototype.initSlimScrollPlugin = function () {
        //You can change the color of scroll bar here
        $.fn.slimScroll && $(".slimscroll").slimScroll({
            height: 'auto',
            position: 'right',
            size: "8px",
            touchScrollStep: 20,
            color: '#9ea5ab'
        });
    },

    //initializing form validation
    Components.prototype.initFormValidation = function () {
        $(".needs-validation").on('submit', function (event) {
            $(this).addClass('was-validated');
            if ($(this)[0].checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                return false;
            }
            return true;
        });
    },

    //initializing custom modal
    Components.prototype.initCustomModalPlugin = function() {
        $('[data-plugin="custommodal"]').on('click', function(e) {
            e.preventDefault();
            var modal = new Custombox.modal({
                content: {
                    target: $(this).attr("href"),
                    effect: $(this).attr("data-animation")
                },
                overlay: {
                    color: $(this).attr("data-overlayColor")
                }
            });
            // Open
            modal.open();
        });
    },

    // Counterup
    Components.prototype.initCounterUp = function() {
        var delay = $(this).attr('data-delay')?$(this).attr('data-delay'):100; //default is 100
        var time = $(this).attr('data-time')?$(this).attr('data-time'):1200; //default is 1200
         $('[data-plugin="counterup"]').each(function(idx, obj) {
            $(this).counterUp({
                delay: 100,
                time: 1200
            });
         });
    },

    //peity charts
    Components.prototype.initPeityCharts = function() {
        $('[data-plugin="peity-pie"]').each(function(idx, obj) {
            var colors = $(this).attr('data-colors')?$(this).attr('data-colors').split(","):[];
            var width = $(this).attr('data-width')?$(this).attr('data-width'):20; //default is 20
            var height = $(this).attr('data-height')?$(this).attr('data-height'):20; //default is 20
            $(this).peity("pie", {
                fill: colors,
                width: width,
                height: height
            });
        });
        //donut
         $('[data-plugin="peity-donut"]').each(function(idx, obj) {
            var colors = $(this).attr('data-colors')?$(this).attr('data-colors').split(","):[];
            var width = $(this).attr('data-width')?$(this).attr('data-width'):20; //default is 20
            var height = $(this).attr('data-height')?$(this).attr('data-height'):20; //default is 20
            $(this).peity("donut", {
                fill: colors,
                width: width,
                height: height
            });
        });

        $('[data-plugin="peity-donut-alt"]').each(function(idx, obj) {
            $(this).peity("donut");
        });

        // line
        $('[data-plugin="peity-line"]').each(function(idx, obj) {
            $(this).peity("line", $(this).data());
        });

        // bar
        $('[data-plugin="peity-bar"]').each(function(idx, obj) {
            var colors = $(this).attr('data-colors')?$(this).attr('data-colors').split(","):[];
            var width = $(this).attr('data-width')?$(this).attr('data-width'):20; //default is 20
            var height = $(this).attr('data-height')?$(this).attr('data-height'):20; //default is 20
            $(this).peity("bar", {
                fill: colors,
                width: width,
                height: height
            });
         });
    },

    Components.prototype.initKnob = function() {
        $('[data-plugin="knob"]').each(function(idx, obj) {
           $(this).knob();
        });
    },

    //initilizing
    Components.prototype.init = function () {
        var $this = this;
        this.initTooltipPlugin(),
        this.initPopoverPlugin(),
        this.initToastPlugin(),
        this.initSlimScrollPlugin(),
        this.initFormValidation(),
        this.initCustomModalPlugin(),
        this.initCounterUp(),
        this.initPeityCharts(),
        this.initKnob();
    },

    $.Components = new Components, $.Components.Constructor = Components

}(window.jQuery),


function ($) {
    'use strict';

    var App = function () {
        this.$body = $('body'),
        this.$window = $(window)
    };

    /**
    Resets the scroll
    */
    App.prototype._resetSidebarScroll = function () {
        // sidebar - scroll container
        $('.slimscroll-menu').slimscroll({
            height: 'auto',
            position: 'right',
            size: "8px",
            color: '#9ea5ab',
            wheelStep: 5,
            touchScrollStep: 20
        });
    },

    /** 
     * Initlizes the menu - top and sidebar
    */
    App.prototype.initMenu = function () {
        var $this = this;

        // Left menu collapse
        $('.button-menu-mobile').on('click', function (event) {
            event.preventDefault();
            $this.$body.toggleClass('sidebar-enable');
            if ($this.$window.width() >= 768) {
                $this.$body.toggleClass('enlarged');
            } else {
                $this.$body.removeClass('enlarged');
            }

            // sidebar - scroll container
            $this._resetSidebarScroll();
        });
        

        // sidebar - scroll container
        $this._resetSidebarScroll();

        // right side-bar toggle
        $('.right-bar-toggle').on('click', function (e) {
            $('body').toggleClass('right-bar-enabled');
        });

        $(document).on('click', 'body', function (e) {
            if ($(e.target).closest('.right-bar-toggle, .right-bar').length > 0) {
                return;
            }

            if ($(e.target).closest('.left-side-menu, .side-nav').length > 0 || $(e.target).hasClass('button-menu-mobile')
                || $(e.target).closest('.button-menu-mobile').length > 0) {
                return;
            }

            $('body').removeClass('right-bar-enabled');
            $('body').removeClass('sidebar-enable');
            return;
        });

        // activate the menu in left side bar based on url
        $(".navigation-menu a").each(function () {
            var pageUrl = window.location.href.split(/[?#]/)[0];
            if (this.href == pageUrl) {  
                $(this).addClass("active");
                $(this).parent().addClass("active"); // add active to li of the current link
                $(this).parent().parent().addClass("in");
                $(this).parent().parent().prev().addClass("active"); // add active class to an anchor
                $(this).parent().parent().parent().addClass("active");
                $(this).parent().parent().parent().parent().addClass("in"); // add active to li of the current link
                $(this).parent().parent().parent().parent().parent().addClass("active");
            }
        });

        // Topbar - main menu
        $('.navbar-toggle').on('click', function (event) {
            $(this).toggleClass('open');
            $('#navigation').slideToggle(400);
        });

        $('.navigation-menu>li').slice(-2).addClass('last-elements');

        $('.navigation-menu li.has-submenu a[href="#"]').on('click', function (e) {
            if ($(window).width() < 992) {
                e.preventDefault();
                $(this).parent('li').toggleClass('open').find('.submenu:first').toggleClass('open');
            }
        });
    },

    /** 
     * Init the layout - with broad sidebar or compact side bar
    */
    App.prototype.initLayout = function () {
        // in case of small size, add class enlarge to have minimal menu
        if (this.$window.width() >= 768 && this.$window.width() <= 1028) {
            this.$body.addClass('enlarged');
        } else {
            if (this.$body.data('keep-enlarged') != true) {
                this.$body.removeClass('enlarged');
            }
        }
    },

    //initilizing
    App.prototype.init = function () {
        var $this = this;
        this.initLayout();
        this.initMenu();
        $.Components.init();
        // on window resize, make menu flipped automatically
        $this.$window.on('resize', function (e) {
            e.preventDefault();
            $this.initLayout();
            $this._resetSidebarScroll();
        });
    },

    $.App = new App, $.App.Constructor = App


}(window.jQuery),
//initializing main application module
function ($) {
    "use strict";
    $.App.init();

    $('.pro-user-name').text(localStorage.getItem('username'));
}(window.jQuery);

// Waves Effect
Waves.init();

if (localStorage['role'] == 'agent') {
    $('.agentRemove').remove();
}

if (localStorage['role'] == 'projectAdmin') {
    $('.projectAdminRemove').remove();
}


$("#loginForm").submit(function(e) {
    e.preventDefault();

    let timerInterval;
    let loader = Swal.fire({
        timer: 30000,
        onBeforeOpen:function () {
            Swal.showLoading()
            timerInterval = setInterval(function() {
            }, 100)
        },
        onClose: function () {
            clearInterval(timerInterval)
        }
    })
    var email_address = $('#emailaddress').val();
    var password = $('#password').val();
    var extension = $('#extension').val();
    var data = JSON.stringify({
        "password": password,
        "username": email_address,
        "extension": extension
    });

    var translator = $('body').translate({ lang: 'en', t: dict });


    var request = $.ajax({
        url: requestURL + "/v1/authentication",
        method: "POST",
        contentType: "application/json",
        data: data,
        dataType: "json"
    });
     
    request.done(function(xhr, textStatus ) {
        loader.close()
        if (textStatus == "success") {
            localStorage.setItem('username', email_address);
            localStorage.setItem('extension', extension);
            localStorage.setItem('token', xhr.token)
        }
      window.location = "/dashboard"
    });
     
    request.fail(function( jqXHR, textStatus ) {
        loader.close()
        if(jqXHR.status == 401) {
            Swal.fire(
                {
                    title: translator.get("LOGIN_label"),
                    text: translator.get("LOGIN_bad_credentials_error_message"),
                    type: 'error',
                    confirmButtonColor: '#6b5eae'
                }
            )
        } else {
            Swal.fire(
                {
                    title: translator.get("LOGIN_label"),
                    text: translator.get("LOGIN_not_allowed_error_message"),
                    type: 'error',
                    confirmButtonColor: '#6b5eae'
                }
            )
        }

      // window.location = "/"
    });
});



$('#langList').change(function(){
    var lang = $('option:selected',this).attr('shortName');
    var lg = $('option:selected').val();
    $('body').attr('data-lang', lang);
    localStorage.setItem('selectedLang', lg);
    localStorage.setItem('selectedShortLang', lang);
    var translator = $('body').translate({ lang: lang, t: dict });
    translator.lang(lang); 
});

getUserProjects = () => {
    var translator = $('body').translate({ lang: localStorage['selectedShortLang'], t: dict });
    $.ajax({
        url: requestURL + '/v1/projects',
        method: "GET",
        headers: { "Bearer": localStorage['token'] },
        contentType: "application/json",
        success: function (data) {
            $('#projectList').select2({
                width: '100%',
                placeholder: 'Select an option',
                // allowClear: true,
            });

            $('#projectListUsers').select2({
                multiple: true,
                width: '100%',
                placeholder: 'Select a project',
                // placeholder: function() {
                //     $(this).data('placeholder');
                // },
                allowClear: true,
            });
            $.each(data, function(key, value) {   
                let projectId,
                    projectName;
                $.each(value, function(k, v) {   
                    if (k == '_id') {
                        projectId = v; 
                    }
                    if (k == 'name') {
                        projectName = v;
                    }
                });
                
                $('#projectList')
                    .append($("<option></option>")
                        .attr("value",projectId)
                        .text(projectName)); 
                $('#projectListUsers')
                    .append($("<option></option>")
                        .attr("value",projectId)
                        .text(projectName)); 
            });

            $("#projectList").val(localStorage['selectedProject']).change();
        },
        error: function(){
            Swal.fire({
                type: 'error',
                title: translator.get('GENERAL_error_title'),
                text: translator.get('GENERAL_error_message'),
                confirmButtonColor: '#188ae2',
                confirmButtonText: translator.get('GENERAL_error_button_text'),
                }).then(function (result) {
                    localStorage.clear();
                    window.location = "/";
            })
        }
    })
}

getWorkspacesList = () => {
    var translator = $('body').translate({ lang: localStorage['selectedShortLang'], t: dict });
    $.ajax({
        url: requestURL + '/v1/workspaces',
        method: "GET",
        headers: { "Bearer": localStorage['token'] },
        contentType: "application/json",
        success: function (data) {

            $('#workspacesListUsers').select2({
                width: '100%',
                placeholder: '',
                minimumResultsForSearch: -1
                // allowClear: true,
            });

            $.each(data, function(key, value) {   
                let projectId,
                    projectName;
                $.each(value, function(k, v) {   
                    if (k == '_id') {
                        workspaceId = v; 
                    }
                    if (k == 'name') {
                        workspaceName = v;
                    }
                });
                

                $('#workspacesListUsers')
                    .append($("<option></option>")
                        .attr("value",workspaceId)
                        .text(workspaceName)); 
            });

        },
        error: function(){
            Swal.fire({
                type: 'error',
                title: translator.get('GENERAL_error_title'),
                text: translator.get('GENERAL_error_message'),
                confirmButtonColor: '#188ae2',
                confirmButtonText: translator.get('GENERAL_error_button_text'),
                }).then(function (result) {
                    localStorage.clear();
                    window.location = "/";
            })
        }
    })
}

hasAdminRole = (role) =>{
    let adminRole = ['superAdmin', 'workspaceAdmin', 'projectAdmin'];
    return adminRole.includes(role.toString());
}

isAgent = (role) => {
    let agent = ['agent'];
    return agent.includes(role.toString());
}

hasExportRights = (role) => {
    let allowedRoles = ['superAdmin', 'workspaceAdmin', 'projectAdmin'];
    return allowedRoles.includes(role.toString());
}

logAction = (action, details) => {

    return true;

}
// TODO could it be as simple as
// return moment.utc(datetime, 'YYYY-MM-DD HH:mm:ss').tz(timezone).format(formatOut)
// What is the reason for clone ?
function convertDateTimeToTZ (datetime, timezone, formatOut = 'MM/DD/YYYY HH:mm:ss') {
  if(!datetime) return ''
  const utcCutoff = moment.utc(datetime, 'YYYY-MM-DD HH:mm:ss');
  const displayCutoff = utcCutoff.clone().tz(timezone);
  const convertedDateTime = displayCutoff.format(formatOut);

  return convertedDateTime;
}

var oktaSignIn = new OktaSignIn({
    // telerion DEV
    // baseUrl: 'https://telerion-cloud.okta.com/',
    // C4 DEV
    // baseUrl: 'https://connectforhealth.oktapreview.com/',
    // C4 PROD
    baseUrl: 'https://connectforhealth.okta.com/',
    logo: 'assets/images/okta-logo.png',
  });

if (window.location.pathname === '/'){

    let searchParams = new URLSearchParams(window.location.search)
    if (!searchParams.has('logout')){
        oktaSignIn.authClient.session.get().then(function (res) {
            // Session exists, show logged in state.
            if (res.status === 'ACTIVE') {
                // console.log('Welcome back, ' + res.login);

                $('#loginForm').find(':input:not(:disabled)').prop('disabled',true);

                doLoginWithOkta(res.userId, res.login);
              
                oktaSignIn.authClient.token.getWithoutPrompt({
                    scopes: ['openid', 'email', 'profile'],
                }).then((tokens) => {
                    tokens.forEach(token => {
                        if (token.idToken) {
                            oktaSignIn.authClient.tokenManager.add('idToken', token);
                        }
                        if (token.accessToken) {
                            oktaSignIn.authClient.tokenManager.add('accessToken', token);
                    }
                });

              }).catch(error => console.error(error));
              return;
            }

            
            
        });

    }

    oktaSignIn.renderEl({
        el: '#widget-container'
    }, function success(res) {

        if (res.status === 'SUCCESS') {
            
            doLoginWithOkta(res.user.id, res.user.profile.login);

        }
      },
      function error(err) {
        // handle errors as needed
        console.error(err);
      }
    );

    
} else {
    if (!localStorage['token']) {
        oktaSignIn.authClient.session.get().then(function (res) {
            // Session exists, show logged in state.
            if (res.status !== 'ACTIVE') {
                logout();
            }
        })
    } else {
        // oktaSignIn.authClient.session.get().then(function (res) {
        //     // Session exists, show logged in state.
        //     if (res.status !== 'ACTIVE') {
        //         logout();
        //     }
        // })
    }
    
}

var logout = async function(){
    localStorage.clear();
    // sessionStorage.clear();
    if (oktaSignIn.authClient) {
        // await oktaSignIn.authClient.tokenManager.clear();
        // await oktaSignIn.authClient.signOut({ 

        //     revokeAccessToken: true

        //   });
    }
    
    window.location = "/?logout=true"
}



openOktaWidget = () => {

    oktaSignIn.authClient.session.get().then(function (res) {
        // Session exists, show logged in state.
        if (res.status === 'ACTIVE') {
            // console.log('Welcome back, ' + res.login);

            $('#loginForm').find(':input:not(:disabled)').prop('disabled',true);

            doLoginWithOkta(res.userId, res.login);
          
            oktaSignIn.authClient.token.getWithoutPrompt({
                scopes: ['openid', 'email', 'profile'],
            }).then((tokens) => {
                tokens.forEach(token => {
                    if (token.idToken) {
                        oktaSignIn.authClient.tokenManager.add('idToken', token);
                    }
                    if (token.accessToken) {
                        oktaSignIn.authClient.tokenManager.add('accessToken', token);
                }
            });

          }).catch(error => console.error(error));
          return;
        } else {
            $("#loginForm").toggle();
            $("#widget-container").toggle();
            $("#oktaLogin").toggle();
            $("#backToLogin").toggle();
        }
    });

    // $("#loginForm").toggle();
    // $("#widget-container").toggle();
    // $("#oktaLogin").toggle();
    // $("#backToLogin").toggle();
}

doLoginWithOkta = (userId = null, userLogin = null) => {
    var translator = $('body').translate({ lang: 'en', t: dict });

    var data = JSON.stringify({
        "userId": userId, 
        "login": userLogin       
    });

    if (userId !== null && userLogin !== null){

        var request = $.ajax({
            url: requestURL + "/v1/authenticationOkta",
            method: "POST",
            contentType: "application/json",
            data: data,
            dataType: "json"
        });

        request.done(function(xhr, textStatus ) {
            if (textStatus == "success") {
                
                const parseJwt = JSON.parse(atob(xhr.token.split('.')[1]));
                localStorage.setItem('token', xhr.token)
                localStorage.setItem('role', parseJwt.role);
                localStorage.setItem('username', userLogin);
                window.location = "/dashboard"
            }
          
        });

        request.fail(function( jqXHR, textStatus ) {
            if(jqXHR.status == 401) {
                Swal.fire(
                    {
                        title: translator.get("LOGIN_label"),
                        text: translator.get("LOGIN_bad_credentials_error_message"),
                        type: 'error',
                        confirmButtonColor: '#6b5eae'
                    }
                )
            } else {
                Swal.fire(
                    {
                        title: translator.get("LOGIN_label"),
                        text: translator.get("LOGIN_not_allowed_error_message"),
                        type: 'error',
                        confirmButtonColor: '#6b5eae'
                    }
                )
            }

        });

        $('#loginForm').find(':input:not(:disabled)').prop('disabled',false);
    }
}

